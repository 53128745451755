import React from 'react'
import { TPost, TRecentPostsProps } from 'types/blog'
import BlogPost from './BlogPost'
import { Box, Flex, Text } from '@chakra-ui/react'

function RecentBlogPosts({ recentPosts }: TRecentPostsProps) {
  return (
    <Box px={{ base: '2rem', md: '4rem', xl: '12rem' }} display="flex" flexDir="column" gap="24px">
      <Text fontSize="32px" fontWeight="semibold">
        Recent Posts
      </Text>

      <Flex
        flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
        gap={{ base: '24', md: 24, lg: '32', xl: '64' }}
        justify={{ lg: 'space-between' }}>
        {/* Large post */}
        <Box width={{ base: '100%', lg: '50%' }}>
          {recentPosts[0] && <BlogPost post={recentPosts[0]} size="large" />}
        </Box>

        {/* Smaller posts */}
        <Box flex="1" display="flex" flexDirection="column" gap={8}>
          {recentPosts.slice(1).map((post: TPost) => (
            <BlogPost key={post.id} post={post} size="small" />
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

export default RecentBlogPosts
