import { useFetchBlogCategories, useFetchBlogPosts } from 'queries/blog'
import BlogPost from './BlogPost'
import { Box, Grid, Text, Button } from '@chakra-ui/react'
import { colors } from 'styles/foundations'

function AllBlogPosts() {
  const { data: categories } = useFetchBlogCategories()
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useFetchBlogPosts()

  const allPosts = data?.pages.flatMap((page) => page.data) || []
  return (
    <Box
      display="flex"
      flexDir="column"
      gap="24px"
      px={{ base: '2rem', md: '4rem', xl: '12rem' }}
      py="40px"
      alignItems="center">
      <Text fontSize="32px" fontWeight="semibold" textAlign="left" width={'100%'}>
        All Posts
      </Text>
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={{ base: '20px', md: '30px', lg: '40px' }}
        width="100%">
        {allPosts.slice(5).map((post) => (
          <Box key={post.id}>
            <BlogPost post={post} size="medium" categories={categories} />
          </Box>
        ))}
      </Grid>
      {hasNextPage && (
        <Button
          size="16px"
          variant="unstyled"
          border={`1px solid ${colors.brand.darkColors[0]}`}
          height="48px"
          px="32px"
          onClick={() => fetchNextPage()}
          isLoading={isFetchingNextPage}
          loadingText="loading"
          display="flex"
          alignItems="center"
          justifyContent="center">
          Load More
        </Button>
      )}
    </Box>
  )
}

export default AllBlogPosts
