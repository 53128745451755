'use client'
import { useState } from 'react'
import { useFetchBlogPosts } from 'queries/blog'
import { ParallaxProvider } from 'react-scroll-parallax'
import NavBar from 'components/navbar'
import BlogFilters from 'components/blog/Filters/BlogFilters'
import RecentBlogPosts from 'components/blog/RecentBlogPosts'
import AllBlogPosts from 'components/blog/AllBlogPosts'
import Footer from 'components/footer'
import ScrollToTopButton from 'components/scroll-to-top'
import { Box, Text } from '@chakra-ui/react'
import Loading from 'components/loading'
import { colors } from 'styles/foundations'

export default function BlogPage() {
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const { data, isLoading } = useFetchBlogPosts(searchTerm, selectedCategory)
  const posts = data?.pages.flatMap((page) => page.data) ?? []

  const recentPosts = posts.slice(0, 5)

  if (isLoading) {
    return (
      <Box
        display="flex"
        gap="24px"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bg={colors.brand.white}>
        <Loading />
        <Text marginTop={'2'}>Loading posts...</Text>
      </Box>
    )
  }

  return (
    <Box bg={colors.brand.white} w="full" display="flex" flexDir="column" gap={0}>
      <ParallaxProvider>
        <ScrollToTopButton />
        <NavBar />
        <BlogFilters
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSearchTerm={setSearchTerm}
        />
        <RecentBlogPosts recentPosts={recentPosts} />
        <AllBlogPosts />
        <Footer />
      </ParallaxProvider>
    </Box>
  )
}
