import { Spinner, Stack } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'styles/foundations'

export default function Loading() {
  return (
    <Stack justifyContent={'center'} h="full" alignItems="center" direction="row">
      <Spinner size="xl" color={colors.brand.primary} />
    </Stack>
  )
}
